.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg {
  background-color: #1e1f21;
  min-height: 100vh;
  /* display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); */
}

body {
  font-family: 'Montserrat', sans-serif !important;
  color: #f6f6f6 !important;
}

.modal-content {
  background-color: #282c34 !important;
}

.text-muted {
  color: #8f8f8f !important;
}

.light-text {
  color: #f6f6f6 !important;
}

.queue-list {
  overflow-y: scroll !important;
  max-height: 400px !important;
}
@media (max-width: 767.98px) {
  .queue-list {
     overflow-y: scroll !important;
     max-height: 200px !important;
   }
}